import "./style.css";

if ("serviceWorker" in navigator) {
  window.addEventListener(
    "load",
    () => {
      navigator.serviceWorker.register("/sw.js");
    },
    { once: true }
  );
}
